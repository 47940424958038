export function getHeightOfHidden(element, invisibleParent?) {
    const invisibleParentStyles = invisibleParent ? invisibleParent.getAttribute('style') : null;
    if (invisibleParent) {
        invisibleParent.setAttribute('style', 'opacity: 0 !important; display: block !important; transition: none !important;');
    }
    element.parentElement.setAttribute('style', 'position: relative !important');
    element.setAttribute('style', 'opacity: 0 !important; height: auto !important; position: absolute !important;');
    const elementHeight = element.offsetHeight;
    element.removeAttribute('style');
    element.parentElement.removeAttribute('style');
    if (invisibleParent) {
        invisibleParent.setAttribute('style', invisibleParentStyles);
    }
    return elementHeight;
}