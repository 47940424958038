import * as A11yDialog from 'a11y-dialog';
import * as YouTubeIframeLoader from 'youtube-iframe';

export class Modal {
    modal: any;
    modalElement: HTMLElement;
    video?: any;
    nativeVideo?: HTMLVideoElement;

    constructor(modalElement: HTMLElement, container?: HTMLElement) {
        const args = container != null ? [modalElement, container] : [modalElement];
        this.modal = new A11yDialog(...args);
        this.modalElement = modalElement;
        this.modal.on('show', () => {
            document.body.style.overflow = 'hidden';
            this.initVideo();
        });
        this.modal.on('hide', () => {
            document.body.style.overflow = '';
            this.pauseVideo();
        })
    }

    initVideo() {
        const video = this.modalElement.querySelector('iframe');
        if (video) {
            this.youtubeInit();
        }
        else {
            const nativeVideo = this.modalElement.querySelector('video');
            this.nativeVideoInit();
        }
    }

    youtubeInit() {
        const youtubeIframe = this.modalElement.querySelector('iframe');
        if (!youtubeIframe.src) {
            const uniqueID = this.modalElement.id;
            YouTubeIframeLoader.load(YT => {
                const playerID = `youtube-player-${uniqueID}`;
                youtubeIframe.id = playerID;
                youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                const youtubePlayer = new YT.Player(playerID, {});
                this.video = youtubePlayer;
            });
        }
    }

    nativeVideoInit() {
        const video = this.modalElement.querySelector('video') as HTMLVideoElement;
        if (!video.src) {
            video.src = video.getAttribute('data-src');
            this.nativeVideo = video;
        }
    }

    pauseVideo() {
        if (this.video) {
            this.video.pauseVideo();
        }
        if (this.nativeVideo) {
            this.nativeVideo.pause();
        }
    }
}