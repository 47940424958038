import { SvgSprite } from './classes/svg-loader';
let debounce = require('lodash.debounce');
let throttle = require('lodash.throttle');
import { reMeasureSubNavs } from './functions/mainNav';
import { HeroVideo } from './classes/heroVideo';
import { Modal } from './classes/modal';
import { Accordion } from './classes/accordion';
import { nodeListToArray } from './functions/helpers/nodeListToArray';
import { Tabs, ITabOptions } from './classes/tabs';

(() => {
    // SVG Sprite Inject
    new SvgSprite('/static/img/sprite.svg');

    const subNavs = Array.prototype.slice.call(document.querySelectorAll('.main-nav-ul1')) as HTMLElement[];
    if (subNavs) {
        reMeasureSubNavs(subNavs);
    }

    // Hero videos
    const heroVideoContainers = Array.prototype.slice.call(document.querySelectorAll('[data-hero-video-container]')) as HTMLElement[];
    heroVideoContainers.forEach(heroVideoContainer => {
        new HeroVideo(heroVideoContainer);
    });

    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]')) as HTMLElement[];
    modals.forEach(modal => {
        new Modal(modal);
    });

    // Accordions
    const accordions = nodeListToArray(document.querySelectorAll('[data-accordion-list]'));
    accordions.forEach(accordion => {
        const oneOpen = accordion.getAttribute('data-one-open-at-a-time');
        new Accordion({
            container: accordion,
            oneOpenAtATime: oneOpen === 'True'
        });
    });

    // Tabs
    const tabs = Array.prototype.slice.call(document.querySelectorAll('[data-tabs-container]')) as HTMLElement[];
    tabs.forEach(tabs => {
        let options: ITabOptions = {
            container: tabs
        }
        new Tabs(options);
    });

    window.addEventListener('resize', debounce(() => {
        if (subNavs) {
            reMeasureSubNavs(subNavs);
        }
    }, 50));

})();