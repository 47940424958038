require('es6-promise').polyfill();
require('isomorphic-fetch');

export class SvgSprite {
    constructor(svgURL: string) {
        fetch(svgURL)
            .then(response => {
                if (response.status !== 200) {
                    console.error("SVG sprite load failed. Status code: " + response.status);
                    return;
                } else {
                    return response.text();
                }
            })
            .then(svg => this.injectSvg(svg));
    }

    injectSvg(data) {
        if (data !== undefined) {
            const newDiv = document.createElement('div');
            newDiv.innerHTML = data;
            document.body.insertBefore(newDiv, document.body.childNodes[0]);
        }
    }

}